import React, { useState } from 'react';

const SurfaceMur = ({ formData, onFormDataChange }) => {
  const [murData, setMurData] = useState({
    description: 'face 1',
    mhauteur: '',
    mlargeur:'',
    total: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setMurData((prevData) => ({
      ...prevData,
      [name]: value,
      total: calculateTotal(prevData.mlargeur, prevData.mhauteur, name, value),
    }));

    onFormDataChange({
      ...formData,
      faceMaison: {
        ...formData.faceMaison,
        [name]: value,
        total: calculateTotal(formData.faceMaison.mlargeur, formData.faceMaison.mhauteur, name, value),
      },
    });
  };

  // Fonction pour calculer le total
  const calculateTotal = (mlargeur, mhauteur, name, value) => {
    if (name === 'mlargeur') {
      return value * mhauteur;
    } else if (name === 'mhauteur') {
      return mlargeur * value;
    }
    return '';
  };

  return (
    <section>
      <h2>Façade de la maison </h2>
      <div>
        <label htmlFor="description">Titre</label>
        <input
          type="text"
          name="description"
          id="description"
          value={murData.description}
          onChange={handleChange}
          placeholder="Quelle face de la maison"
        />
      </div>

      <div>
        <label htmlFor="ml">Largeur</label>
        <input
          type="number"
          name="mlargeur"
          id="ml"
          value={murData.mlargeur}
          min={0}
          onChange={handleChange}
          placeholder="Largeur du mur"
        />
      </div>

      <div>
        <label htmlFor="mh">Hauteur</label>
        <input
          type="number"
          name="mhauteur"
          id="mh"
          value={murData.mhauteur}
          min={0}
          onChange={handleChange}
          placeholder="Hauteur du mur"
        />
      </div>
    </section>
  );
};

export default SurfaceMur;
