import React from 'react';
import Services from '../../components/Services/Services';

const Isolation = () => {
  const serviceDescription = `
  Un expert en isolation est spécialisé dans l'amélioration de l'efficacité énergétique des bâtiments en installant des 
  matériaux isolants dans les murs, les toits et les planchers. Que ce soit pour réduire vos factures d'énergie, améliorer 
  le confort thermique de votre maison, ou pour une rénovation énergétique, nous sommes là pour répondre à tous vos besoins 
  en isolation.
  `;
   const IsolationImage = () =>'./images/services/isolation.png'

  // const slides = [
  //   { image: '/images/image1.jpg', title: 'Image 1', description: 'Description de l\'image 1' },
  //   { image: '/images/image2.jpg', title: 'Image 2', description: 'Description de l\'image 2' },
  // ];
  return (
    <Services
      serviceName="Isolation"
      serviceDescription={serviceDescription}
      serviceIcon="🛠️"
      backgroundImage={IsolationImage()}
    />
  );
};

export default Isolation;
