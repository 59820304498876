import React, { useEffect, useState } from 'react';

const SurfaceToit = ({ formData, onFormDataChange }) => {
    const [toitData, setToitData] = useState({
        pointe: ''
    });

    useEffect(() => {
        const calculateTotal = () => {
            if (formData.surfaceToit.pointe !== '' && formData.surfaceToit.pointe !== '0') {
                const l = formData.faceMaison.mlargeur / 2;
                const h = toitData.pointe - formData.faceMaison.mhauteur;
                const t = h * l
                return { l, h, t };
            }
            return { l: '', h: '' };
        };

        setToitData((prevData) => ({
            ...prevData,
            total: calculateTotal(),
        }));

        onFormDataChange({
            ...formData,
            surfaceToit: {
                ...formData.surfaceToit,
                total: calculateTotal(),
            },
        });
    }, [formData.faceMaison.mlargeur, formData.faceMaison.mhauteur, formData.surfaceToit.pointe]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setToitData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        onFormDataChange({
            ...formData,
            surfaceToit: {
                ...formData.surfaceToit,
                [name]: value,
                total: calculateTotal(),
            },
        });
    }

    const calculateTotal = () => {
        if (toitData.pointe !== '') {
            const l = formData.faceMaison.mlargeur / 2;
            const h = toitData.pointe - formData.faceMaison.mhauteur;
            return { l, h };
        }
        return { l: '', h: '' };
    };

    return (
        <section>
            <h2>Surface Du toit</h2>
            <div>
                <label htmlFor="pointe">point hauteur du toit</label>
                <input
                    type="number"
                    name="pointe"
                    id="pointe"
                    value={toitData.pointe}
                    onChange={handleChange}
                    placeholder="Quelle est la hauteur du toit"
                />
            </div>
        </section>
    );
};

export default SurfaceToit;
