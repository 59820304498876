import React from 'react';
import Services from '../../components/Services/Services';

const Electricien = () => {
  let serviceDescription = `
  Notre équipe d'électriciens qualifiés se charge de l'installation, de la réparation et de la maintenance 
  électrique pour les particuliers et les professionnels. Que ce soit pour des travaux neufs, des rénovations 
  ou des dépannages, nous intervenons rapidement et efficacement. Votre sécurité et votre confort sont notre priorité : 
  c'est pourquoi nous utilisons des matériaux et des équipements conformes aux normes en vigueur. Faites appel à notre 
  expertise pour tous vos besoins en électricité.
  `;
   const electricienImage = () =>'./images/services/electricien.png'

  return (
    <div>
      <Services
        serviceName="Electricien"
        serviceDescription={serviceDescription}
        serviceIcon="⚡"
        backgroundImage={electricienImage()}
      />
    </div>
  );
};

export default Electricien;
