import React from 'react';
import styles from './Services.module.css';
import Slider from '../Slider/Slider';
import BtnContact from '../BtnContact/BtnContact';
import { useLocation } from 'react-router-dom';

const Services = ({ serviceName, serviceDescription, serviceIcon, backgroundImage, slides = null }) => {

  // Transformer les points en sauts de ligne HTML
  serviceDescription = serviceDescription.replace(/\.\s+/g, '.<br />');

  const location = useLocation();

  let serviceLabel;
  // Extraire la dernière partie de l'URL (le slug)
  const url = location.pathname.split('/').filter(Boolean).pop();

  if(url === 'electricite') {
    serviceLabel = 'd\'électricien'
  } else if(url === 'isolationExterieure') {
    serviceLabel = 'd\'isolation'
  } else if(url === 'hydrogommage') {
    serviceLabel = 'd\'hydrogommage'
  } else {
    serviceLabel = 'de ' + serviceName
  }

  return (
    <div>
      <section className={styles.parallax} style={{ 
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover', }}
      >
        <div className={styles.parallaxText}>
          <h1>{serviceName}</h1>
          <div className={styles.serviceIcon}>{serviceIcon}</div>
        </div>
      </section>
      
      <section className={styles.serviceDescription}>
        <h2>À propos de nos services {serviceLabel.toLowerCase()}</h2>
        <p dangerouslySetInnerHTML={{ __html: serviceDescription }} />
      </section>

      <section className={styles.trustSection}>
        <h2>Pourquoi nous faire confiance ?</h2>
        <ul>
          <li>Professionnels expérimentés et qualifiés</li>
          <li>Service rapide et fiable</li>
          <li>Réponse garantie sous 24h</li>
          <li>Satisfaction garantit</li>
        </ul>
      </section>

       { slides !== null ? <Slider slides={slides}/> : ''}
      
      <BtnContact/>
    </div>
  );
};

export default Services;
