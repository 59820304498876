import React from 'react';
import Services from '../../components/Services/Services';

const Crepis = () => {
  
  const serviceDescription = `
    Un crépisseur professionnel est spécialisé dans l'application d'enduits décoratifs et de protection sur les murs extérieurs 
    et intérieurs des bâtiments. Que ce soit pour améliorer l'esthétique de votre maison, protéger vos murs contre les intempéries,
    ou rénover une façade vieillissante, nous sommes là pour répondre à tous vos besoins en crépissage.
  `;
   const crepisImage = () =>'./images/services/crepîs.png'
  //  const slides = [
  //   { image: '/images/image1.jpg', title: 'Image 1', description: 'Description de l\'image 1' },
  //   { image: '/images/image2.jpg', title: 'Image 2', description: 'Description de l\'image 2' },
  
  // ];

  return (
    <Services
      serviceName="Crépis"
      serviceDescription={serviceDescription}
      serviceIcon="🏠"
      backgroundImage={crepisImage()}
    />
  );
};

export default Crepis;
