import React, { useState } from 'react';

const SelectWindows = ({ element, value, onChange }) => {
  const handleInputChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div>
      <h2>Ebrasement {element}</h2>
      <label htmlFor={`nombreDe${element}`}>Nombre de {element}</label>
      <input
        type="number"
        name={`nombreDe${element}`}
        id={`nombreDe${element}`}
        defaultValue={value}
        min={0}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default SelectWindows;
