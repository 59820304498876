import React from 'react';
import Services from '../../components/Services/Services';

const Plaquiste = () => {
  const serviceDescription = `
  Notre équipe de plaquistes professionnels est spécialisée dans la pose de plaques de plâtre et l'aménagement intérieur. 
  Que ce soit pour la création de cloisons, l'isolation acoustique ou thermique, ou encore la rénovation de votre intérieur, 
  nous vous apportons notre savoir-faire et notre expérience. Nous travaillons avec des matériaux de qualité afin de garantir 
  des finitions impeccables et durables. Faites confiance à notre expertise pour transformer vos espaces selon vos besoins et vos envies.
`;

  const plaquisteImage = () =>'./images/services/plaquiste.png'
  // const slides = [
  //   { image: '/images/image1.jpg', title: 'Image 1', description: 'Description de l\'image 1' },
  //   { image: '/images/image2.jpg', title: 'Image 2', description: 'Description de l\'image 2' },
  // ];
  return (
    <Services
      serviceName="Plaquiste"
      serviceDescription={serviceDescription}
      serviceIcon="🧱"
      backgroundImage={plaquisteImage()}
    />
  );
};

export default Plaquiste;
