function getImageData() {

    const images = [
        { image: '/images/peinture/stefano-avant/img1.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-apres/img1.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-avant/img2.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-apres/img2.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-avant/img3.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-apres/img3.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-avant/img4.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-apres/img4.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-avant/img5.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-apres/img5.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-avant/img6.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-apres/img6.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-avant/img7.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-apres/img7.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-avant/img8.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-apres/img8.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-avant/img9.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-apres/img9.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-avant/img10.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-apres/img10.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-avant/img11.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-apres/img11.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-avant/img12.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-apres/img12.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-avant/img13.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-apres/img13.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-avant/img14.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-apres/img14.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-avant/img15.jpg', title: 'peinture', description: 'Chambre' },
        { image: '/images/peinture/stefano-apres/img15.jpg', title: 'peinture', description: 'Chambre' },
      ];

    return images
}

export default getImageData;

 