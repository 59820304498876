import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';

const Header = () => {
  return (
    <header className={styles.headerContainer}>
      <div className={styles.logo}><Link to="/">Goffeney</Link></div>
      <nav className={styles.nav}>
        <Link to="/" className={styles.navLink}>Accueil</Link>
        <Link to="/about" className={styles.navLink}>À propos</Link>
        <Link to="/contact" className={styles.navLink}>Contact</Link>
      </nav>
    </header>
  );
};

export default Header;
