import React from 'react';
import Services from '../../components/Services/Services';

const Demoussage = () => {
  const serviceDescription = `
  Le démoussage de toiture est essentiel pour préserver l'intégrité et l'esthétique de votre toit. 
  Nos experts interviennent pour éliminer les mousses, lichens et autres débris qui peuvent endommager votre toiture. 
  Nous utilisons des méthodes efficaces et respectueuses de l'environnement pour nettoyer et traiter votre toit. 
  Faites confiance à notre équipe pour maintenir votre toiture en parfait état et prolonger sa durée de vie.
  `;
   const demoussageImage = () =>'./images/services/demoussage.png'
  //  const slides = [
  //   { image: '/images/image1.jpg', title: 'Image 1', description: 'Description de l\'image 1' },
  //   { image: '/images/image2.jpg', title: 'Image 2', description: 'Description de l\'image 2' },
  // ];

  return (
    <Services
      serviceName="Démoussage"
      serviceDescription={serviceDescription}
      serviceIcon="🧹"
      backgroundImage={demoussageImage()}
    />
  );
};

export default Demoussage;
