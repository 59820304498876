import React from 'react';
import {Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Contact from './pages/Contact/Contact';
import About from './pages/About/About';
import Plomberie from './pages/Plomberie/Plomberie';
import Crepis from './pages/Crepis/Crepis';
import Isolation from './pages/Isolation/Isolation';
import Peinture from './pages/Peinture/Peinture';
import Plaquiste from './pages/Plaquiste/Plaquiste';
import Electricien from './pages/Electricien/Electricien';
import Demoussage from './pages/Demoussage/Demoussage';
import Hydrogommage from './pages/Hydrogommage/Hydrogommage';
import Devis from './pages/Devis/Devis';
import LegalNotice from './pages/LegalNotice/LegalNotice';

function App() {
  return (
      <>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/devis" element={<Devis />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/plomberie" element={<Plomberie />} />
          <Route path="/crepis" element={<Crepis />} />
          <Route path="/isolationExterieure" element={<Isolation />} />
          <Route path="/peinture" element={<Peinture />} />
          <Route path="/plaquiste" element={<Plaquiste />} />
          <Route path="/electricite" element={<Electricien />} />
          <Route path="/demoussage" element={<Demoussage />} />
          <Route path="/hydrogommage" element={<Hydrogommage />} />
          <Route path="/legalNotice" element={<LegalNotice />} />
        </Routes>
        <Footer />
      </>
  );
}

export default App;
