import React from 'react';
import styles from './About.module.css';

const About = () => {
  return (
    <div className={styles.aboutContainer}>
      <section className={styles.hero}>
        <div className={styles.heroText}>
          <h1>À propos de <span>Goffeney</span></h1>
          <h3>Votre partenaire de confiance pour tous vos projets de construction et de rénovation</h3>
        </div>
      </section>

      <section className={styles.companyInfo}>
        <h2>Notre histoire</h2>
        <p>
          Goffeney, une filiale de la réputée entreprise Eurofaçade, a été fondée en 2023 pour répondre aux besoins des professionnels et des particuliers dans le secteur du bâtiment et des travaux publics (BTP). Avec plus de 33 ans d'expérience accumulée par Eurofaçade, Goffeney se positionne comme un partenaire de confiance, offrant une gamme complète de services pour tous vos projets.
        </p>
      </section>

      <section className={styles.servicesSection}>
        <h2>Nos services pour les professionnels</h2>
        <div className={styles.serviceList}>
          <div className={styles.serviceItem}>
            <h3>Électricité</h3>
            <p>Installation, maintenance et mise aux normes des systèmes électriques.</p>
          </div>
          <div className={styles.serviceItem}>
            <h3>Plomberie</h3>
            <p>Solutions complètes pour l'installation, la réparation et l'entretien de vos équipements sanitaires.</p>
          </div>
          <div className={styles.serviceItem}>
            <h3>Isolation</h3>
            <p>Amélioration de l'efficacité énergétique de vos bâtiments grâce à des techniques d'isolation avancées.</p>
          </div>
          <div className={styles.serviceItem}>
            <h3>Crépis et Placo</h3>
            <p>Travaux de finition pour donner à vos murs et plafonds une apparence impeccable.</p>
          </div>
          <div className={styles.serviceItem}>
            <h3>Peinture</h3>
            <p>Services de peinture intérieure et extérieure pour un rendu esthétique de qualité.</p>
          </div>
          <div className={styles.serviceItem}>
            <h3>Démoussage et Hydrogommage</h3>
            <p>Nettoyage en profondeur et entretien des surfaces pour prolonger leur durée de vie et préserver leur apparence.</p>
          </div>
        </div>
      </section>

      <section className={styles.particularsSection}>
        <h2>Une solution intégrée pour les particuliers</h2>
        <p>
          Pour nos clients particuliers, Goffeney s'engage à coordonner les différents intervenants nécessaires à la réalisation de leurs projets. Nous comprenons l'importance de la transparence et de la fiabilité, particulièrement après avoir rencontré des particuliers victimes de services inaboutis ou de pratiques malhonnêtes.
        </p>
      </section>

      <section className={styles.whyChooseUs}>
        <h2>Pourquoi choisir Goffeney ?</h2>
        <p>
          Avec Goffeney, vous avez l'assurance de travailler avec une équipe dédiée, qui met un point d'honneur à garantir votre satisfaction. Nous nous distinguons par notre engagement de qualité, de transparence et de respect des délais. Que ce soit pour des travaux de construction, de rénovation ou d'entretien, nous vous accompagnons à chaque étape pour que vos projets se réalisent dans les meilleures conditions.
        </p>
      </section>
    </div>
  );
};

export default About;
