// components/forms/SelectPorte.js
import React from 'react';

const BtnSubmit= () => {

  return (
    <input type="submit" value='Valider' />
  );
};

export default BtnSubmit;
