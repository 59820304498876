// components/forms/SelectPorte.js
import React from 'react';
import BtnContactStyle from './BtnContact.module.css'

const BtnContact = () => {

  return (
    <section  className={BtnContactStyle.heroDevis}>

        <div className={BtnContactStyle.cta}>
          <h2>Prêt à démarrer votre projet ?</h2>
          <button className={BtnContactStyle.ctaButton}><a href='/contact'>Contactez nous</a></button>
        </div>

    </section>
  );
};

export default BtnContact;
