import React from 'react';
import styles from './LegalNotice.module.css';

const LegalNotice = () => {
  return (
    <div className={styles.legalNoticeContainer}>
      <h1 className={styles.title}>Mentions Légales</h1>
      
      <section className={styles.section}>
        <h2>Éditeur du Site</h2>
        <p><strong>Nom de l'entreprise :</strong> Goffeney</p>
        <p><strong>Adresse :</strong> 3 rue Louis Neel, 67200 STRASBOURG, France</p>
        <p><strong>Téléphone :</strong> 0760078495</p>
        <p><strong>Email :</strong> andreagoffeney@hotmail.fr</p>
        <p><strong>Forme juridique :</strong> SARL</p>
        <p><strong>Capital social :</strong> 8 000 €</p>
        <p><strong>SIRET :</strong> 98 530 376 700 018 </p>
        <p><strong>RCS :</strong> Strasbourg B 123 456 789</p>
        <p><strong>Numéro de TVA intracommunautaire :</strong> FR49 985 303 767 </p>
      </section>

      <section className={styles.section}>
        <h2>Directeur de la Publication</h2>
        <p><strong>Nom :</strong> Goffeney Andrea</p>
        <p><strong>Responsabilité :</strong> Directeur de la publication</p>
      </section>

      <section className={styles.section}>
        <h2>Hébergement du Site</h2>
        <p><strong>Nom de l'hébergeur :</strong> OVH</p>
        <p><strong>Adresse de l'hébergeur :</strong> 2 rue Kellermann, 59100 Roubaix, France</p>
        <p><strong>Email :</strong> support@ovh.com</p>
      </section>

      <section className={styles.section}>
        <h2>Propriété Intellectuelle</h2>
        <p>
          Le contenu du site, incluant mais non limité aux textes, images, vidéos, graphiques, logos, et icônes, est la propriété exclusive de Goffeney, sauf mention contraire. Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l'accord écrit préalable de Goffeney.
        </p>
      </section>

      <section className={styles.section}>
        <h2>Protection des Données Personnelles</h2>
        <p>
          Conformément à la loi "Informatique et Libertés" du 6 janvier 1978 modifiée et au Règlement Général sur la Protection des Données (RGPD), vous disposez d’un droit d'accès, de rectification, d'opposition et de suppression des données vous concernant. Vous pouvez exercer ce droit en nous contactant à l'adresse email : contact@goffeney.com.
        </p>
      </section>

      <section className={styles.section}>
        <h2>Cookies</h2>
        <p>
          Le site Goffeney utilise des cookies pour améliorer l'expérience utilisateur et à des fins de statistiques de visite. En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de ces cookies.
        </p>
      </section>

      <section className={styles.section}>
        <h2>Limitation de Responsabilité</h2>
        <p>
          Goffeney s'efforce de fournir sur le site des informations aussi précises que possible. Cependant, l'entreprise ne pourra être tenue responsable des omissions, des inexactitudes, et des carences dans la mise à jour, qu'elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.
        </p>
      </section>

      <section className={styles.section}>
        <h2>Droit Applicable</h2>
        <p>
          Le présent site est soumis au droit français. En cas de litige, et après une tentative de recherche de solution amiable, les tribunaux français seront seuls compétents pour régler ce différend.
        </p>
      </section>
    </div>
  );
};

export default LegalNotice;
