import React, { useState } from 'react';
import styles from './Slider.module.css';

const Slider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 2 >= slides.length ? 0 : prevIndex + 2
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 2 : prevIndex - 2
    );
  };

  return (
    <div className={styles.slider}>
      <button onClick={prevSlide} className={styles.arrowLeft}></button>
      <div className={styles.slides}>
        {slides.slice(currentIndex, currentIndex + 2).map((slide, index) => (
          <div className={styles.slide} key={index}>
            <img src={slide.image} alt={slide.title} className={styles.image} />
          </div>
        ))}
      </div>
      <button onClick={nextSlide} className={styles.arrowRight}></button>
    </div>
  );
};

export default Slider;
