import React from 'react';
import Services from '../../components/Services/Services';
import getImageData from './imageObjet';

const Peinture = () => {
  const serviceDescription = `
  Notre équipe de peintres professionnels vous propose une expertise complète en matière de peinture intérieure et extérieure. 
  Que ce soit pour rafraîchir l'aspect esthétique de votre maison, protéger vos murs extérieurs contre les intempéries, ou 
  simplement pour un changement de décor, nous vous offrons un service de qualité et des conseils personnalisés. Nous utilisons 
  des matériaux de haute qualité et nous assurons une finition soignée, pour des résultats durables.
`;

const peintureImage = () =>'./images/services/peinture.png'

return (
  <Services
    serviceName="Peinture"
    serviceDescription={serviceDescription}
    serviceIcon="🎨"
    backgroundImage={peintureImage()}
    slides={getImageData()}
  />
);
};

export default Peinture;
