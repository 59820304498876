import React, { useState } from 'react';
import styles from './ContactForm.module.css';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
    to_name: 'Goffeney'
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('');

  const validatePhone = (phone) => {
    const phoneRegex = /^(\+33|0)[1-9](\d{2}){4}$/;
    return phoneRegex.test(phone);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const errors = {};
    Object.keys(formData).forEach((key) => {
      if (formData[key].trim() === '') {
        errors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} est requis`;
      }
    });

    if (!validatePhone(formData.phone)) {
      errors.phone = 'Numéro de téléphone invalide';
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsSubmitting(false);
      return;
    }

    const templateParams = {
      to_name: formData.to_name,
      from_name: formData.name,
      from_email: formData.email,
      from_phone: formData.phone,
      subject: formData.subject,
      message: formData.message,
    };

    emailjs.send('service_goffeney', 'template_9o6juqk', templateParams, 'ghjaklmGU1RFoKF5l')
      .then(
        () => {
          setSubmitStatus('Message envoyé avec succès!');
          setFormData({
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
            to_name: 'Goffeney'
          });
          setFormErrors({});
        },
        (error) => {
          setSubmitStatus(`Erreur d'envoi: ${error.text}`);
        }
      )
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <form className={styles.contactForm} onSubmit={handleSubmit}>
      <div className={styles.formGroup}>
        <label htmlFor="name">Nom :</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className={formErrors.name ? styles.errorInput : ''}
          required
        />
        {formErrors.name && <p className={styles.errorMsg}>{formErrors.name}</p>}
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="email">Email :</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className={formErrors.email ? styles.errorInput : ''}
          required
        />
        {formErrors.email && <p className={styles.errorMsg}>{formErrors.email}</p>}
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="phone">Téléphone :</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          className={formErrors.phone ? styles.errorInput : ''}
          required
        />
        {formErrors.phone && <p className={styles.errorMsg}>{formErrors.phone}</p>}
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="subject">Sujet :</label>
        <select
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          className={formErrors.subject ? styles.errorInput : ''}
          required
        >
          <option value="">Sélectionnez un sujet</option>
          <option value="Démoussage">Démoussage</option>
          <option value="Peinture">Peinture</option>
          <option value="Isolation">Isolation</option>
          <option value="Crépis">Crépis</option>
          <option value="Plomberie">Plomberie</option>
          <option value="Hydrogommage">Hydrogommage</option>
          <option value="Électricité">Électricité</option>
          <option value="Placo">Placo</option>
        </select>
        {formErrors.subject && <p className={styles.errorMsg}>{formErrors.subject}</p>}
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="message">Message :</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          className={formErrors.message ? styles.errorInput : ''}
          rows={6}
          required
        />
        {formErrors.message && <p className={styles.errorMsg}>{formErrors.message}</p>}
      </div>
      <button type="submit" className={styles.submitButton} disabled={isSubmitting}>
        {isSubmitting ? 'Envoi en cours...' : 'Envoyer'}
      </button>
      {submitStatus && <p className={styles.submitStatus}>{submitStatus}</p>}
    </form>
  );
};

export default ContactForm;
