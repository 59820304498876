import React from 'react';
import Services from '../../components/Services/Services';

const Plomberie = () => {
  const serviceDescription = `
    Un plombier professionnel s'occupe de l'installation, de la réparation et de l'entretien des systèmes de plomberie
    dans les bâtiments résidentiels et commerciaux. Que ce soit pour une fuite, une nouvelle installation ou une rénovation,
    nous sommes là pour répondre à tous vos besoins en plomberie.
  `;
  const plumberImage = () =>'./images/services/plombier.png'

  //  const slides = [
  //   { image: '/images/image1.jpg', title: 'Image 1', description: 'Description de l\'image 1' },
  //   { image: '/images/image2.jpg', title: 'Image 2', description: 'Description de l\'image 2' },
  // ];
  return (
    <Services
      serviceName="Plomberie"
      serviceDescription={serviceDescription}
      serviceIcon="🚰"
      backgroundImage={plumberImage()}
    />
  );
};

export default Plomberie;
