import React from 'react';
import styles from './Contact.module.css'; // Fichier CSS pour la page de contact
import ContactForm from '../../components/ContactForm/ContactForm';

const ContactPage = () => {
  return (
    <div className={styles.contactPage}>
      <h2>Nous contacter</h2>
      <div className={styles.contactDetails}>
      </div>
      <div className={styles.contactForm}>
        {/* Composant du formulaire de contact */}
        <div className={styles.responseTime}>
          <p>Devis garanti sous 72h</p>
        </div>
        <ContactForm />
      </div>
    </div>
  );
};

export default ContactPage;
