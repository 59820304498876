import React from 'react';
import styles from './Footer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt, faClock } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerContent}>
        <div className={styles.companyInfo}>
          <h2>Goffeney</h2>
          <p><FontAwesomeIcon icon={faMapMarkerAlt} /> 3 rue Louis Neel, 67200 STRASBOURG</p>
          <p><FontAwesomeIcon icon={faPhoneAlt} /> 07.60.07.84.95</p>
          <p><FontAwesomeIcon icon={faClock} /> Du lundi au vendredi, de 8h00 à 18h00</p>
        </div>
        <div className={styles.legal}>
          <a href='legalNotice'>Mention légale</a>
        </div>
        <div className={styles.cta}>
          <h2>Besoin de plus d’informations ? </h2>
          <button className={styles.ctaButton}>
            <a href='/devis'>Faire une simulation</a>
          </button>
        </div>
      </div>
      <div className={styles.footerBottom}>
        &copy; {new Date().getFullYear()} Goffeney. Tous droits réservés.
      </div>
    </footer>
  );
};

export default Footer;
