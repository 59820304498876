import React, { useEffect, useState } from 'react';
import SurfaceMur from '../../components/forms/SurfaceMur';
import BtnSubmit from '../../components/forms/BtnSubmit';
import SurfaceToit from '../../components/forms/SurfaceToit';
import ElementForm from '../../components/forms/ElementForm';
import SelectWindows from '../../components/forms/SelectWindows';

import DevisStyles from './Devis.module.css';

const Devis = () => {
  const [formData, setFormData] = useState({
    faceMaison: { description: 'face 1', mhauteur: '', mlargeur: '', total: '' },
    surfaceToit: { pointe: '', total: { l: '', h: '', t: '' } },
    fenetres: [],
    portes: [],
  });

  const [totalEbrasement, setTotalEbrasement] = useState('');

  useEffect(() => {
    const fenetre = formData.fenetres;
    const porte = formData.portes;

    let totalEmbrasements = 0;

    if (Array.isArray(fenetre) && fenetre.length > 0) {
      totalEmbrasements += fenetre.reduce((acc, element) => {
        return acc + parseFloat(element.ebrasement || 0);
      }, 0);
    }

    if (Array.isArray(porte) && porte.length > 0) {
      totalEmbrasements += porte.reduce((acc, element) => {
        return acc + parseFloat(element.ebrasement || 0);
      }, 0);
    }

    setTotalEbrasement(totalEmbrasements.toFixed(2));
  }, [formData.fenetres, formData.portes]);

  const handleFormDataChange = (updatedFormData) => {
    setFormData(updatedFormData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Données soumises :', formData);
  };

  const handleNombreDeElementChange = (value, elementType) => {
    const nombreDeElement = parseInt(value, 10);
    const existingElements = elementType === 'fenetre' ? formData.fenetres : formData.portes;

    const newElements = Array.from({ length: nombreDeElement }, (_, index) => ({
      id: index + existingElements.length,
      description: '',
      tEHauteur: '',
      tELargeur: '',
      ebrasement: 0,
    }));

    const updatedElements = [...existingElements, newElements];

    setFormData((prevData) => ({
      ...prevData,
      [elementType === 'fenetre' ? 'fenetres' : 'portes']: updatedElements,
    }));
  };

  return (
    <main className={DevisStyles.container}>
      <section className={DevisStyles.calculatorSection}>
        <h1 className={DevisStyles.calculatorTitle}>Nouveau simulateur de devis</h1>

        <form onSubmit={handleSubmit} className={DevisStyles.form}>
          <SurfaceMur formData={formData} onFormDataChange={handleFormDataChange} />
          <SurfaceToit formData={formData} onFormDataChange={handleFormDataChange} />

          <SelectWindows
            element="fenetre"
            value={formData.fenetres.length}
            onChange={(value) => handleNombreDeElementChange(value, 'fenetre')}
          />
          {Array.isArray(formData.fenetres) &&
            formData.fenetres.map((fenetres, index) => (
              <ElementForm
                key={index}
                elementType="fenetre"
                formData={fenetres}
                onFormDataChange={handleFormDataChange}
                index={index}
              />
            ))}

          <SelectWindows
            element="porte"
            value={formData.portes.length}
            onChange={(value) => handleNombreDeElementChange(value, 'porte')}
          />
          {Array.isArray(formData.portes) &&
            formData.portes.map((portes, index) => (
              <ElementForm
                key={index}
                elementType="porte"
                formData={portes}
                onFormDataChange={handleFormDataChange}
                index={index}
              />
            ))}

          <BtnSubmit />
        </form>
      </section>

      <section className={DevisStyles.resultSection}>
        <article>
          {formData.faceMaison.mlargeur !== '' && formData.faceMaison.mhauteur !== '' &&
            formData.faceMaison.mlargeur !== '0' && formData.faceMaison.mhauteur !== '0' &&
            formData.faceMaison.mlargeur > 0 && formData.faceMaison.mhauteur > 0 && (
              <>
                <div>
                  <h2>Surface de Mur {formData.faceMaison.description}</h2>
                  <span className={DevisStyles.formule}>formule : L x H = ?</span>
                  <p>{formData.faceMaison.mlargeur} x {formData.faceMaison.mhauteur} = {parseFloat(formData.faceMaison.total).toFixed(2)} m²</p>
                </div>

                {formData.surfaceToit.total.l !== '' && formData.surfaceToit.total.h !== '' && (
                  <div>
                    <h2>Surface du Toit {formData.faceMaison.description}</h2>
                    <span className={DevisStyles.formule}>formule : L / 2 = ?</span>
                    <p>{formData.faceMaison.mlargeur} / 2 = {parseFloat(formData.surfaceToit.total.l).toFixed(2)}</p>
                    <span className={DevisStyles.formule}>formule : H (Pointe de toit) - H = ?</span>
                    <p>{parseFloat(formData.surfaceToit.pointe).toFixed(2)} - {parseFloat(formData.faceMaison.mhauteur).toFixed(2)} = {parseFloat(formData.surfaceToit.total.h).toFixed(2)}</p>
                    <p>Total : {parseFloat(formData.surfaceToit.total.h).toFixed(2)} x {parseFloat(formData.surfaceToit.total.l).toFixed(2)} = {parseFloat(formData.surfaceToit.total.t).toFixed(2)}</p>
                  </div>
                )}
              </>
            )}
        </article>

        <article>
          {formData.fenetres.length > 0 && (
            <div>
              <h2>Surface des fenêtres</h2>
              <span className={DevisStyles.formule}>formule : H + L + L * 0.4 = ?</span>
              {formData.fenetres.map((element, index) => (
                <article key={index}>
                  <p>
                    <span className={DevisStyles.formule}>Ébrasement {element.description} : </span> {element.tEHauteur} +
                    {element.tELargeur} + {element.tELargeur} = {parseFloat(element.ebrasement).toFixed(2)}
                  </p>
                </article>
              ))}
            </div>
          )}
        </article>

        <article>
          {formData.portes.length > 0 && (
            <div>
              <h2>Surface des portes</h2>
              <span className={DevisStyles.formule}>formule : H + L + L * 0.4 = ?</span>
              {formData.portes.map((element, index) => (
                <article key={index}>
                  <p>
                    <span className={DevisStyles.formule}>Ébrasement {element.description} : </span> {element.tEHauteur} +
                    {element.tELargeur} + {element.tELargeur} = {parseFloat(element.ebrasement).toFixed(2)}
                  </p>
                </article>
              ))}
            </div>
          )}
        </article>

        <article>
          {totalEbrasement !== '0.00' && (
            <article>
              <h2>Total des Ébrasements</h2>
              <p>{parseFloat(totalEbrasement).toFixed(2)} m²</p>
              <h2 className={DevisStyles.sTotal}>Surface totale avec isolation</h2>
              <p>
                {formData.faceMaison.total} + {formData.surfaceToit.total.t} + {totalEbrasement} = 
                <span className={DevisStyles.total}>
                  {(Number(formData.faceMaison.total) + Number(formData.surfaceToit.total.t) + Number(totalEbrasement)).toFixed(2)} m²
                </span>
              </p>
              <h2 className={DevisStyles.sTotal}>Échafaudage</h2>
              <p>
                {formData.faceMaison.mlargeur} x {formData.surfaceToit.pointe} =
                <span className={DevisStyles.total}>
                  {(Number(formData.faceMaison.mlargeur) * Number(formData.surfaceToit.pointe)).toFixed(2)} m²
                </span>
              </p>
            </article>
          )}
        </article>
      </section>
    </main>
  );
};

export default Devis;
