import React from 'react';
import Services from '../../components/Services/Services';

const Hydrogommage = () => {
  const serviceDescription = `
   L'hydrogommage est une méthode douce et efficace pour nettoyer et restaurer les surfaces en pierre, en bois, en métal et bien d'autres matériaux. 
  Nos spécialistes en hydrogommage utilisent un mélange d'eau et de micro-abrasifs pour éliminer les saletés, graffitis, peintures et autres contaminants sans abîmer le matériau d'origine. 
  Faites appel à notre équipe pour redonner une nouvelle vie à vos surfaces extérieures tout en respectant l'environnement et la durabilité des matériaux.
  `;
   const HydrogommageImage = () =>'./images/services/hydrogommage.png'

  // const slides = [
  //   { image: '/images/image1.jpg', title: 'Image 1', description: 'Description de l\'image 1' },
  //   { image: '/images/image2.jpg', title: 'Image 2', description: 'Description de l\'image 2' },
  // ];
  return (
    <Services
      serviceName="Hydrogommage"
      serviceDescription={serviceDescription}
      serviceIcon="💧"
      backgroundImage={HydrogommageImage()}
    />
  );
};

export default Hydrogommage;
