import React, { useState, useEffect } from 'react';

const ElementForm = ({formData, onFormDataChange, elementType, index }) => {
  const [elementData, setElementData] = useState({
    id: '',
    description: `${elementType}`,
    tEHauteur: '',
    tELargeur: '',
    ebrasement: 0
  });

  useEffect(() => {
    onFormDataChange((prevData) => {

      const updatedElements  = elementType === 'fenetre'
      ? [...prevData.fenetres]
      : [...prevData.portes];

      updatedElements [index] = elementData;

      const ebrasement = calculateTotal();

      return {
        ...prevData,
        [`${elementType}s`]: updatedElements,
        id: index,
        description: elementType + ' ' + index,
        ebrasement: ebrasement
      };
    });

    setElementData((prevData) => ({
      ...prevData,
      description: elementType + ' ' + index,
      ebrasement: calculateTotal()
    }));
    
  }, [elementData.tEHauteur, elementData.tELargeur, elementData.ebrasement, index, elementType ]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setElementData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const calculateTotal = () => {
    if (
      elementData.tEHauteur !== '' &&
      elementData.tEHauteur !== '0' &&
      elementData.tELargeur !== '' &&
      elementData.tELargeur !== '0'
    ) {

      const total = ((parseFloat(elementData.tEHauteur) + 2 * parseFloat(elementData.tELargeur)) * 0.4).toFixed(2);
      return total;
    }
    return 0;
  };

  return (
    <section>
      <h2>Surface {elementType}(s)</h2>

      <div>
        <label htmlFor={`description`}>Titre</label>
        <input
          type="text"
          name={'description'}
          id={'description'}
          value={elementData['description'] }
          onChange={handleChange}
          placeholder={`Quelle ${elementType} 1`}
        />
      </div>

      <div>
        <label htmlFor="tEHauteur">Hauteur</label>
        <input
          type="number"
          name="tEHauteur"
          id="tEHauteur"
          min={0}
          value={elementData.tEHauteur}
          onChange={handleChange}
          placeholder={`Hauteur de la ${elementType}`}
        />
      </div>

      <div>
        <label htmlFor="tELargeur">Largeur</label>
        <input
          type="number"
          name="tELargeur"
          id="tELargeur"
          min={0}
          value={elementData.tELargeur}
          onChange={handleChange}
          placeholder={`Largeur de la ${elementType}`}
        />
      </div>
    </section>
  );
};

export default ElementForm;
