import React from 'react';
import styles from './Home.module.css';
import { Link } from 'react-router-dom';
import BtnContact from '../../components/BtnContact/BtnContact';

const Home = () => {
  return (
    <div>
      <section className={styles.hero}>
        <div className={styles.heroText}>
          <h1>Rénove <span>Multiservices</span></h1>
          <p>Nous vous accompagnons pour la réalisation de vos travaux</p>
        </div>
      </section>

      <section className={styles.trustSection}>
        <h2 className={styles.trustTitle}>Pourquoi nous faire confiance ?</h2>
        <p className={styles.introText}>Nous sommes là pour vous accompagner à chaque étape de vos projets</p>
        <div className={styles.trustPoints}>
          <div className={styles.trustPoint}>
            <p className={styles.highlight}>
              <strong>Notre mission</strong> 
            </p>
              <span> Centraliser, coordonner et suivre vos travaux de manière efficace</span>
          </div>
          <div className={styles.trustPoint}>
            <p className={styles.highlight}>
              <strong>Notre force</strong>
            </p>
              <span> La qualité de nos partenariats, collaboration avec des experts reconnus pour leur compétences et leur fiabilité</span>
          </div>
          <div className={styles.trustPoint}>
            <p className={styles.highlight}>
              <strong>Notre engagement</strong>
            </p>
              <span> Nous mettons tout en œuvre pour vous garantir un résultat soigné, conforme à vos attentes.</span>
          </div>
          <div className={styles.trustPoint}>
            <p className={styles.highlight}>
              <strong>Notre objectif</strong>
            </p>
              <span> Votre satisfaction</span>
          </div>
        </div>
      </section>


      <section className={styles.servicesWrapper}>
  <div className={styles.centerCircle}>
    <div className={styles.centerCircleText}>
      <h2>Nos Secteurs d'activités</h2>
    </div>
  </div>
  <div className={styles.services}>
    <Link to="/peinture" className={styles.navLink}>
      <div className={`${styles.service} ${styles.service1}`}>
        <div className={styles.serviceIcon}>🎨</div>
        <h3>Peinture</h3>
        <p className={styles.serviceDesc}>Découvrez nos services de peinture de haute qualité pour vos projets résidentiels et commerciaux.</p>
      </div>
    </Link>
    <Link to="/isolationExterieure" className={styles.navLink}>
      <div className={`${styles.service} ${styles.service2}`}>
        <div className={styles.serviceIcon}>🛠️</div>
        <h3>Isolation</h3>
        <p className={styles.serviceDesc}>Optimisez l'efficacité énergétique de votre maison avec nos solutions d'isolation extérieure.</p>
      </div>
    </Link>
    <Link to="/crepis" className={styles.navLink}>
      <div className={`${styles.service} ${styles.service3}`}>
        <div className={styles.serviceIcon}>🏠</div>
        <h3>Crépis</h3>
        <p className={styles.serviceDesc}>Nos experts appliquent des crépis durables et esthétiques pour protéger et embellir vos façades.</p>
      </div>
    </Link>
    <Link to="/plomberie" className={styles.navLink}>
      <div className={`${styles.service} ${styles.service4}`}>
        <div className={styles.serviceIcon}>🚰</div>
        <h3>Plomberie</h3>
        <p className={styles.serviceDesc}>Installation et réparation de systèmes de plomberie par nos artisans qualifiés.</p>
      </div>
    </Link>
    <Link to="/hydrogommage" className={styles.navLink}>
      <div className={`${styles.service} ${styles.service5}`}>
        <div className={styles.serviceIcon}>💧</div>
        <h3>Hydrogommage</h3>
        <p className={styles.serviceDesc}>Techniques douces de nettoyage et de rénovation de surfaces grâce à l'hydrogommage.</p>
      </div>
    </Link>
    <Link to="/demoussage" className={styles.navLink}>
      <div className={`${styles.service} ${styles.service6}`}>
        <div className={styles.serviceIcon}>🧹</div>
        <h3>Démoussage</h3>
        <p className={styles.serviceDesc}>Services de démoussage professionnels pour préserver la longévité de vos toitures.</p>
      </div>
    </Link>
    <Link to="/electricite" className={styles.navLink}>
      <div className={`${styles.service} ${styles.service7}`}>
        <div className={styles.serviceIcon}>⚡</div>
        <h3>Électricité</h3>
        <p className={styles.serviceDesc}>Travaux d'électricité générale pour des installations sûres et conformes.</p>
      </div>
    </Link>
    <Link to="/plaquiste" className={styles.navLink}>
      <div className={`${styles.service} ${styles.service8}`}>
        <div className={styles.serviceIcon}>🧱</div>
        <h3>Placo</h3>
        <p className={styles.serviceDesc}>Solutions en plaquisterie pour l'aménagement intérieur et la finition des surfaces.</p>
      </div>
    </Link>
  </div>
</section>


      <BtnContact />
      
    </div>
  );
};

export default Home;
